<template>
  <div class="col-6 mb-1 mb-sm-0">
    <div class="custom-control custom-checkbox my-2">
      <input
        @click="toggleFacility"
        type="checkbox"
        class="custom-control-input"
        :value="id"
        :id="'facility-without-unit-field-' + id"
        :checked="selected"
        name="attributes[]"
        :readonly="readOnly"
      />
      <label class="custom-control-label" :for="'facility-without-unit-field-' + id">{{
        name
      }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'facility-without-unit-field',
  props: ['name', 'selected', 'id', 'readOnly'],
  methods: {
    toggleFacility(data) {
      if (this.readOnly !== true) {
        let self = this;
        self.$store.commit('listing/form/TOGGLE_SELECTED_FACILITY', data.target.value);
      }
    },
  },
};
</script>

<style scoped></style>
